var updatedAgent;
var agentSnapshot;
var connectedContact;
var agentEndpoints;
var lastFirstConnectionStatus;
var lastTransferStatus;
var lastAgentStatus;
var contactSnapshot;

var isOnCall = false;
var numberOfConnectedConnections = 0;
var containerDiv = document.getElementById("containerDiv");
var agentDirectEndPoint = null;
var operataEventBus = null;
var operataFeedback = null;
var popoverOpened = false;
timerObjs = {};
connect.core.initCCP(containerDiv, {
  ccpUrl: configVision.ccpUrl,
  loginUrl: configVision.ccpLogin,
  loginPopup: true,
  softphone: {
    allowFramedSoftphone: false
  }
});

connect.core.initSoftphoneManager({ allowFramedSoftphone: true });

// Operata stats collector options
const operataOptions = {
  operataGroupID: configOperata.operataClientID,
  operataGroupSecret: configOperata.operataClientSecret,
  awsCCPUrl: configVision.ccpUrl,
  loadAgentAssist: false,
  ccpLogCollection: configOperata.ccpLogCollection
};

const eventBus = connect.core.getEventBus();
eventBus.subscribe(connect.EventType.TERMINATED, function () {
  setState("offline");
  sessionStorage.clear();
  window.location.href = `${cognitoUrl}/logout?client_id=${cognitoAppId}&logout_uri=${encodeURI(selfUrl)}`;
});

const encodeFormData = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const refreshToken = async () => {
  const requestBody = {
    grant_type: "refresh_token",
    client_id: cognitoAppId,
    refresh_token: sessionStorage.getItem("refresh_token")
  };
  const authString = `${cognitoAppId}:${cognitoAppSecret}`;

  const res = await fetch(`${cognitoUrl}/oauth2/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${btoa(authString)}`
    },
    body: encodeFormData(requestBody)
  });

  const data = await res.text();
  const { id_token, access_token } = JSON.parse(data);
  sessionStorage.setItem("id_token", id_token);
  sessionStorage.setItem("access_token", access_token);
};

const tokenCheck = async token => {
  const expireTime = JSON.parse(atob(token.split(".")[1])).exp;
  const date = new Date(expireTime * 1000);
  if (new Date() >= date) {
    await refreshToken();
  }
};

const timerCleaner = currentConnections => {
  const keys = Object.keys(timerObjs);
  const unuseKeys = keys.filter(k => !currentConnections.includes(k));
  unuseKeys.forEach(k => {
    clearInterval(timerObjs[k].timer);
    delete timerObjs[k];
  });
};

function getTimerObj(contactId, divId) {
  if (timerObjs[contactId]) {
    clearInterval(timerObjs[contactId].timer);
  }

  try {
    var temp = {
      duration: 0,
      paused: false,
      timer: setInterval(
        (_id, _div) => {
          if (timerObjs[_id].paused) return;
          timerObjs[_id].duration += 1000;
          $("#" + _div).text(calculateElapsedTime(timerObjs[_id].duration));
        },
        1000,
        contactId,
        divId
      )
    };
    timerObjs[contactId] = temp;
    temp.timer;
    return temp;
  } catch (error) {
    console.log("=== error ===", error);
    return null;
  }
}

connect.agent(function (agent) {
  console.log(connect.agent.initialized + " after");
  $("#mainContainer").show();
  $(".vfAuthVisible").show();
  $(".vfAuthHidden").hide();

  console.log("connect.agent");

  updatedAgent = agent;
  setState("offline");
  refreshScreenAfterLogin(agent);

  agent.onRefresh(function (agent) {
    if (!updatedAgent) {
      refreshScreenAfterLogin(agent);
    }

    $("#loginbutton").hide();

    agentSnapshot = agent.toSnapshot();

    //console.log({ x: "agent.onRefresh", agent });

    //console.log(agentSnapshot);
    var currentAgentStatus = updatedAgent.getStatus();

    if (!lastAgentStatus || lastAgentStatus.name != currentAgentStatus.name) {
      document.title = currentAgentStatus.name + " - Amazon Connect";

      // code is used to fix call back status issue
      const agentStates = updatedAgent.getAgentStates();

      if (lastAgentStatus && (lastAgentStatus.type == "system" || lastAgentStatus.type == "error" || lastAgentStatus.type === "not_routable")) {
        let removeable = true;
        if (
          lastAgentStatus.type === "not_routable" &&
          agentStates.findIndex(s => s.name === lastAgentStatus.name && s.type === "not_routable") > -1
        ) {
          removeable = false;
        }
        if (removeable) $('#agent-Statuses-Container-list option[value="' + lastAgentStatus.name + '"]').remove();
      }

      if (currentAgentStatus.type == "system" || currentAgentStatus.type == "error" || currentAgentStatus.type === "not_routable") {
        let addable = true;
        if (
          currentAgentStatus.type === "not_routable" &&
          agentStates.findIndex(s => s.name === currentAgentStatus.name && s.type === "not_routable") > -1
        )
          addable = false;
        if (addable)
          $("#agent-Statuses-Container-list").append(
            '<option value="' + currentAgentStatus.name + '" type=' + currentAgentStatus.type + ">" + currentAgentStatus.name + "</option>"
          );
      }
      /*
      if (lastAgentStatus && (lastAgentStatus.type == "system" || lastAgentStatus.type == "error")) {
        $('#agent-Statuses-Container-list option[value="' + lastAgentStatus.name + '"]').remove();
      }

      if (currentAgentStatus.type == "system" || currentAgentStatus.type == "error") {
        $("#agent-Statuses-Container-list").append(
          '<option value="' + currentAgentStatus.name + '" type=' + currentAgentStatus.type + ">" + currentAgentStatus.name + "</option>"
        );
      }
      */
      $("#agent-Statuses-Container-list").val(currentAgentStatus.name);
    }

    lastAgentStatus = agent.getStatus();

    if (currentAgentStatus.name == "CallingCustomer" || currentAgentStatus.name == "Busy" || currentAgentStatus.name == "PendingBusy") {
      //console.log(agentStatus);

      // disable agent status change when busy
      $("#agent-Statuses-Container-list").prop("disabled", true);
    } else {
      $("#agent-Statuses-Container-list").prop("disabled", false);
    }

    if (currentAgentStatus.name == "MissedCallAgent") {
      updateSoftphoneView("inactive");
    }
  });

  agent.onStateChange(async function (agentStateChange) {
    console.log(`>>>>>>>>>>>>>>>>>>>${agentStateChange}`);
    let { agent, newState, oldState } = agentStateChange;
    console.log(`agent : ${agent.getConfiguration().username} change status to ${newState}`);
    if (!Boolean(sessionStorage.getItem("id_token"))) {
      return;
    }
    await tokenCheck(sessionStorage.getItem("id_token"));
    fetch(urlBase.updateAgentUrl, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("id_token")}`
      },
      body: JSON.stringify({
        name: agent.getConfiguration().username,
        agentstatus: newState
      })
    })
      .then(res => res.text())
      .then(data => {
        console.log("agent status change success");
      })
      .catch(err => console.log(err));
  });

  agent.onRoutable(function (agent) {
    console.log({ x: "agent.onRoutable", agent });

    updateSoftphoneView("inactive");
  });

  agent.onNotRoutable(function (agent) {
    console.log("agent.onNotRoutable");
  });

  agent.onOffline(function (agent) {
    console.log("agent.onOffline");

    updateSoftphoneView("inactive");
  });

  agent.onAfterCallWork(function (agent) {
    console.log("agent.onAfterCallWork");

    $("#inputNumber").val("");
    isOnCall = false;
    updateSoftphoneView("callEnded");
  });

  agent.onError(function (agent) {
    console.log("== agent.onError");
  });
});

connect.contact(function (contact) {
  console.log("connect.contact");

  // clear previous results
  $("#contactsViewCall").hide();
  $("#accountsList").hide();

  connectedContact = contact;

  contactSnapshot = contact.toSnapshot();

  if (contactSnapshot.contactData && contactSnapshot.contactData.connections) {
    var inboundConnection = null;
    var outboundConnection = null;
    var connections = contactSnapshot.contactData.connections;
    for (var i = 0; i < connections.length; i++) {
      if (connections[i].type === "inbound") {
        inboundConnection = connections[i];
        break;
      }
      if (connections[i].type === "outbound") {
        outboundConnection = connections[i];
        break;
      }
    }

    if (inboundConnection !== null && inboundConnection.address !== null) {
      var inboundPhoneNumber = inboundConnection.address.phoneNumber;

      $("#softphoneIncomingNumber").text(inboundPhoneNumber);

      searchCustomersByPhoneNumber(inboundPhoneNumber);
    }

    if (outboundConnection != null && outboundConnection.address != null) {
      var outboundPhoneNumber = outboundConnection.address.phoneNumber;

      if (outboundPhoneNumber.lastIndexOf(":") != -1) {
        outboundPhoneNumber = outboundPhoneNumber.substring(outboundPhoneNumber.lastIndexOf(":") + 1, outboundPhoneNumber.lastIndexOf("@"));
      }

      $("#softphoneOutgoingNumber").text(outboundPhoneNumber);
      console.log({
        a: "#softphoneOutgoingNumber",
        value: outboundPhoneNumber
      });
    }
  }

  contact.onRefresh(function (contact) {
    //console.log("contact.onRefresh");

    connectedContact = contact;

    try {
      refreshConnections(contact);
    } catch (error) {
      console.log(error);
    }
    //refreshConnections(contact);
  });

  contact.onConnecting(function (contact) {
    console.log("contact.onConnecting");

    var inboundConnection = null;
    var outboundConnection = null;
    let contactSnapshot = contact.toSnapshot();

    var connections = contactSnapshot.contactData.connections;
    for (var i = 0; i < connections.length; i++) {
      if (connections[i].type === "inbound") {
        inboundConnection = connections[i];
        break;
      }
      if (connections[i].type === "outbound") {
        outboundConnection = connections[i];
        break;
      }
    }

    $("#inactiveSoftphone").css("display", "none");
    console.log(connections);
    console.log("________calling__________", contact.isInbound());
    if (contact.isInbound()) {
      let tempNumber = inboundConnection.address.phoneNumber;
      console.log("incoming call number", tempNumber);
      $("#softphoneIncomingNumber").text(tempNumber);
      $("#incomingNumberDiv").text(tempNumber);
      $("#incomingNumberDisplayer").text(tempNumber);
      console.log({
        a: "#softphoneIncomingNumber",
        value: inboundConnection.address.phoneNumber
      });
      updateSoftphoneView("incoming");
    } else {
      let tempNumber = String(outboundConnection.address.phoneNumber); // e.g. "+61412345678"
      const displayNumber = tempNumber.includes(":") ? tempNumber.split(":")[1].split("@")[0] : tempNumber;

      $("#softphoneOutgoingNumber").text(displayNumber);
      console.log({ a: "#softphoneOutgoingNumber", value: tempNumber });
      updateSoftphoneView("calling");
    }
  });

  contact.onConnected(function (contact) {
    console.log("contact.onConnected");
    isOnCall = true;
    updateSoftphoneView("onCall");
    let activeConnection = contact.getInitialConnection();
    let { phoneNumber } = activeConnection.getEndpoint();
    console.log(`>>>>>>>>>>>>>>>>${phoneNumber}>>>>>>>>>>>>>>>>>>`);
    if (phoneNumber === `sip:${agentToAgentCallNo}@lily-outbound` || phoneNumber === agentToAgentCallNo) {
      console.log(agentDirectEndPoint);
      contact.addConnection(agentDirectEndPoint, {
        success: function () {
          console.log("trans make");
          contact.getActiveInitialConnection().destroy({
            success: function () {
              console.log("first line disconnect");
            },
            failure: function () {
              console.log("first line connot disconnect");
            }
          });
        },
        failure: function () {
          console.log("fail to transfer");
        }
      });
    }
  });

  contact.onIncoming(function (contact) {
    console.log("contact.onIncoming");

    updateSoftphoneView("incoming");
  });

  contact.onAccepted(function (contact) {});

  contact.onEnded(function (contact) {
    console.log("contact.onEnded");
    $("#transfer-call-on-off-btn").css("display", "block");
    isOnCall = false;
    updateSoftphoneView("callEnded");
    accessibleMessage("Call Ended", "assertive");
  });
});

refreshScreenAfterLogin = function (agent) {
  console.log({ x: "refreshScreenAfterLogin", agent });

  function compare(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  agent.getAgentStates().sort(compare);

  var agentConfig = agent.getConfiguration();
  $.each(agent.getAgentStates(), function (index, value) {
    if (value.name !== "LAP Callouts") {
      $("#agent-Statuses-Container-list").append('<option value="' + value.name + '" type=' + value.type + ">" + value.name + "</option>");
    }
  });

  $(".agent-status-container").show();

  $("#loginbutton").hide();
  $("#agentName").html(agentConfig.name);

  $("#loginuser").show();
  $("#agentLogout").show();

  if ($("#agentName").text() != "") {
    $("#contactsButton").prop("disabled", false);
    $("#contactsButton").css("cursor", "pointer");

    $("#accountsButton").prop("disabled", false);
    $("#accountsButton").css("cursor", "pointer");
    $("#searchContactInput").prop("disabled", false);
  }

  $("#loginuser").show();
  $("#agentLogout").css("display", "");
};

refreshConnections = function (contact) {
  //console.log({ x: "refreshConnections", contact });
  var contactSnap = contact.toSnapshot();
  if (contactSnap.contactData && contactSnap.contactData.connections) {
    var currentConnections = contactSnap.contactData.connections;
    numberOfConnectedConnections = currentConnections.filter(c => {
      if (c.type != "agent") {
        return c.state.type == "connected" || c.state.type == "hold";
      } else {
        return false;
      }
    }).length;

    console.log({ currentConnections });

    if (currentConnections.length === 2) {
      var firstConnection = currentConnections[1];
      // remove all connection timers which is not current connection
      timerCleaner([firstConnection.connectionId]);

      $("#transferCallerTbl").hide();

      if (firstConnection.state && firstConnection.state.type) {
        if (firstConnection.state.type == "hold") {
          $("#withIncomingDiv").text("On Hold");
          $("#holdIncomingNumberBtn").text("Resume");
          $("#holdIncomingNumberBtn").attr("aria-label", "Resume");
          $("#incomingCallTbl").css("display", "block");
        } else if (firstConnection.state.type == "connected") {
          $("#withIncomingDiv").text("With");
          $("#holdIncomingNumberBtn").text("Hold");
          $("#holdIncomingNumberBtn").attr("aria-label", "Hold");
          $("#incomingCallTbl").css("display", "block");
          if (!timerObjs[firstConnection.connectionId]) {
            getTimerObj(firstConnection.connectionId, "softphoneCallStart");
          }
        } else if (firstConnection.state.type == "disconnected") {
          $("#softphoneCallStart").text("00:00:00");
          $("#incomingCallTbl").hide();
          if (timerObjs[firstConnection.connectionId]) {
            clearInterval(timerObjs[firstConnection.connectionId].timer);
            delete timerObjs[firstConnection.connectionId];
          }
        }
      }
      lastFirstConnectionStatus = firstConnection.state.type;
    } else if (currentConnections.length === 3) {
      var transferConnection = currentConnections[2];
      var firstConnection = currentConnections[1];

      // remove all connection timers which is not current connection
      timerCleaner([firstConnection.connectionId, transferConnection.connectionId]);

      var transferNumber = transferConnection.endpoint.phoneNumber;

      if (transferConnection.state.type != "disconnected") {
        $("#transferToPhone").val(String(transferNumber).toLowerCase());
        $("#transferNumberDiv").text($("#transferToPhone").val());
      }
      if (firstConnection.state && firstConnection.state.type) {
        if (firstConnection.state.type == "hold") {
          $("#withIncomingDiv").text("On Hold");
          $("#holdIncomingNumberBtn").text("Resume");
          $("#holdIncomingNumberBtn").attr("aria-label", "Resume");
          $("#incomingCallTbl").css("display", "block");
        } else if (firstConnection.state.type == "connected") {
          $("#withIncomingDiv").text("With");
          $("#holdIncomingNumberBtn").text("Hold");
          $("#holdIncomingNumberBtn").attr("aria-label", "Hold");
          $("#incomingCallTbl").css("display", "block");
          if (!timerObjs[firstConnection.connectionId]) {
            getTimerObj(firstConnection.connectionId, "softphoneCallStart");
          }
        } else if (firstConnection.state.type == "disconnected") {
          $("#softphoneCallStart").text("00:00:00");
          $("#incomingCallTbl").hide();
          if (timerObjs[firstConnection.connectionId]) {
            clearInterval(timerObjs[firstConnection.connectionId].timer);
            delete timerObjs[firstConnection.connectionId];
          }
        }
      }
      if (transferConnection.state && transferConnection.state.type) {
        if (transferConnection.state.type == "connecting") {
          $("#transferCallerDiv").text("Transferring To");
          $("#leaveCallBtn").css("display", "block");
          $("#transferCallerTbl").css("display", "block");
          $("#transfer-call-on-off-btn").css("display", "none");
          $("#transferCallBtn").hide();
        } else if (transferConnection.state.type == "hold") {
          $("#transferCallerDiv").text("On Hold");
          $("#holdTransferCallerBtn").text("Resume");
          $("#holdTransferCallerBtn").attr("aria-label", "Resume");
          $("#leaveCallBtn").css("display", "block");
          $("#transferCallerTbl").css("display", "block");
          $("#transfer-call-on-off-btn").css("display", "none");
          $("#transferCallBtn").hide();
        } else if (transferConnection.state.type == "connected") {
          $("#transferCallerDiv").text("With");
          $("#holdTransferCallerBtn").text("Hold");
          $("#holdTransferCallerBtn").attr("aria-label", "Hold");
          $("#leaveCallBtn").css("display", "block");
          $("#transferCallerTbl").css("display", "block");
          $("#transfer-call-on-off-btn").css("display", "none");
          $("#transferCallBtn").hide();
          if (!timerObjs[transferConnection.connectionId]) {
            getTimerObj(transferConnection.connectionId, "softphoneCallStartTransfer");
          }
        } else if (transferConnection.state.type == "disconnected") {
          $("#leaveCallBtn").hide();
          $("#transferCallerTbl").hide();
          $("#transfer-call-on-off-btn").css("display", "block");
          $("#softphoneCallStartTransfer").text("00:00:00");
          if (timerObjs[transferConnection.connectionId]) {
            clearInterval(timerObjs[transferConnection.connectionId].timer);
            delete timerObjs[transferConnection.connectionId];
          }
          if (timerObjs[firstConnection.connectionId] && firstConnection.state.type === "disconnected") {
            clearInterval(timerObjs[firstConnection.connectionId].timer);
            delete timerObjs[firstConnection.connectionId];
          }
        }
      }
      lastTransferStatus = transferConnection.state.type;
    } else {
      $("#transferCallerTbl").hide();
      $("#transfer-call-on-off-btn").css("display", "block");
      $("#leaveCallBtn").hide();
    }
  }
};

updateSoftphoneView = function (softphoneStatus) {
  console.log({
    x: "updateSoftphoneView ----------------------",
    softphoneStatus
  });

  $("#inactiveSoftphone").hide();
  $("#softphoneCalling").hide();
  $("#softphoneIncoming").hide();
  $("#softphoneOnCall").hide();
  $("#softphoneCallEnded").hide();

  switch (softphoneStatus) {
    case "inactive":
      $("#inactiveSoftphone").css("display", "flex");
      break;
    case "calling":
      $("#softphoneCalling").css("display", "flex");
      break;
    case "incoming":
      let agentAutoAccept = false;
      if (Boolean(updatedAgent)) {
        agentAutoAccept = updatedAgent.getConfiguration().softphoneAutoAccept;
      }
      $("#softphoneIncoming").css("display", "flex");
      $(".button-accept").prop("disabled", false).css({ background: "#1cbad4", color: "#ffffff" });
      /*
      if (agentAutoAccept) {
        //  $(".button-accept").css("display", "none");
      } else {
        $(".button-accept").prop("disabled", false).css({ background: "#1cbad4", color: "#ffffff" });
      }
      */
      // $("#softphoneIncoming #contactsViewCall").show();

      break;
    case "onCall":
      $("#softphoneOnCall").css("display", "flex");
      break;
    case "callEnded":
      $("#softphoneCallEnded").css("display", "flex");
      $("#inactiveSoftphone").css("display", "flex");
      break;
  }
};

leaveCall = function () {
  accessibleMessage("Transfer completed", "assertive");

  connectedContact.getConnections()[0].destroy({
    success: function () {},
    failure: function () {}
  });
};

placeCall = function (phone) {
  accessibleMessage("calling", "assertive");
  if (isOnCall) {
    transferManual(phone);
    return;
  }
  if (updatedAgent) {
    var inputNumber = phone;
    if (!inputNumber) {
      inputNumber = $("#inputcountrycode").val() + $("#inputNumber").val();
    }

    var endpoint = connect.Endpoint.byPhoneNumber(inputNumber);
    console.log(endpoint);
    updatedAgent.connect(endpoint, {
      success: function () {
        accessibleMessage("calling", "assertive");

        $("#incomingNumberDiv").text(inputNumber);
      },
      failure: function () {}
    });
  }
};

cancelCall = function () {
  if (connectedContact) {
    if (connectedContact.getAgentConnection()) {
      connectedContact.getAgentConnection().destroy({
        success: function () {},
        failure: function () {}
      });
    }

    accessibleMessage("Call Ended", "assertive");

    if (connectedContact) {
      if (connectedContact.getActiveInitialConnection() !== null) {
        connectedContact.getActiveInitialConnection().destroy({
          success: function () {},
          failure: function () {}
        });
      }

      if (connectedContact.getSingleActiveThirdPartyConnection() !== null) {
        connectedContact.getSingleActiveThirdPartyConnection().destroy({
          success: function () {},
          failure: function () {}
        });
      }
    }
  }
};

cancelIncomingCall = function () {
  if (connectedContact) {
    accessibleMessage("Line 1 dropped", "assertive");

    connectedContact.getActiveInitialConnection().destroy({
      success: function () {},
      failure: function () {}
    });
  }
};

cancelTransferCall = function () {
  if (connectedContact) {
    accessibleMessage("Line 2 dropped", "assertive");
    connectedContact.getSingleActiveThirdPartyConnection().destroy({
      success: function () {},
      failure: function () {}
    });
  }
};

acceptCall = function () {
  if (connectedContact) {
    connectedContact.accept({
      success: function () {
        //  $(".button-accept").prop("disabled", true).css("opacity", 0.3);
        $(".button-accept").prop("disabled", true);
      },
      failure: function (err) {
        console.error(JSON.stringify(err));
      }
    });
  }
};

enterQueue = function () {
  accessibleMessage("Going back in queue", "assertive");
  $("#enter-queue-btn").css("display", "none");
  setState("routable");
};

holdIncomingCall = function () {
  console.log("holdIncomingCall");

  if (connectedContact) {
    let aliveFirstConn = connectedContact.getActiveInitialConnection();
    let aliveSecondConn = connectedContact.getSingleActiveThirdPartyConnection();
    if (aliveFirstConn) {
      if (aliveFirstConn.isOnHold()) {
        if (aliveSecondConn && !aliveSecondConn.isOnHold()) {
          connectedContact.conferenceConnections({
            success: function () {
              accessibleMessage("Line 1 resumed, start conference", "assertive");
            },
            failure: function () {
              accessibleMessage("can not start a conference", "assertive");
            }
          });
        } else {
          aliveFirstConn.resume({
            success: function () {
              accessibleMessage("Line 1 resumed", "assertive");
            },
            failure: function () {
              accessibleMessage("can not resume line 1", "assertive");
            }
          });
        }
      } else {
        aliveFirstConn.hold({
          success: function () {
            accessibleMessage("Line 1 hold", "assertive");
          },
          failure: function () {
            accessibleMessage("can not hold line 1", "assertive");
          }
        });
      }
    }
  }
};

holdTransferer = function () {
  console.log("holdTransferCall");

  if (connectedContact) {
    let aliveFirstConn = connectedContact.getActiveInitialConnection();
    let aliveSecondConn = connectedContact.getSingleActiveThirdPartyConnection();
    if (aliveSecondConn) {
      if (aliveSecondConn.isOnHold()) {
        if (aliveFirstConn && !aliveFirstConn.isOnHold()) {
          connectedContact.conferenceConnections({
            success: function () {
              accessibleMessage("Line 2 resumed, start conference", "assertive");
            },
            failure: function () {
              accessibleMessage("can not start a conference", "assertive");
            }
          });
        } else {
          aliveSecondConn.resume({
            success: function () {
              accessibleMessage("Line 2 resumed", "assertive");
            },
            failure: function () {
              accessibleMessage("can not resume line 2", "assertive");
            }
          });
        }
      } else {
        aliveSecondConn.hold({
          success: function () {
            accessibleMessage("Line 2 hold", "assertive");
          },
          failure: function () {
            accessibleMessage("can not hold line 2", "assertive");
          }
        });
      }
    }
  }
};

declineCall = function () {
  if (connectedContact) {
    var conn = connectedContact.getActiveInitialConnection();

    if (conn) {
      conn.destroy({
        success: function () {},
        failure: function () {}
      });
    }
  }
};

changeStatusInConnect = function (name) {
  console.log("changeStatusInConnect - " + name);

  if (updatedAgent) {
    var routableState = updatedAgent.getAgentStates().filter(function (state) {
      return state.name === name;
    })[0];
    console.log("routableState", routableState);
    if (routableState.name === "Available") enterQueue();
    setState(null, name);
  }
};

changeAgentStatusDropDown = function (elem) {
  var selectedName = $(elem).val();

  console.log("changeAgentStatusDropDown - " + selectedName);

  changeStatusInConnect(selectedName);
};

calculateElapsedTime = function (durationMs) {
  let distance = durationMs;

  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  if (hours > 0) {
    if (Math.floor(hours / 10)) {
      hours = hours;
    } else {
      hours = "0" + hours;
    }
  } else {
    hours = "00";
  }

  if (minutes > 0) {
    if (Math.floor(minutes / 10)) {
      minutes = minutes;
    } else {
      minutes = "0" + minutes;
    }
  } else {
    minutes = "00";
  }

  if (seconds > 0) {
    if (Math.floor(seconds / 10)) {
      seconds = seconds;
    } else {
      seconds = "0" + seconds;
    }
  } else {
    seconds = "00";
  }

  var result = hours + ":" + minutes + ":" + seconds;
  return result;
};

transferToAgent = function (agentEndPointName) {
  console.log(`transfer to ${agentEndPointName}`);
  updatedAgent.getEndpoints(updatedAgent.getAllQueueARNs(), {
    success: function (data) {
      console.log("Get Queue ARNs");
      let { endpoints } = data;
      let matchedEndpoint = endpoints.filter(ep => String(ep.name).toLowerCase() === agentEndPointName);
      if (matchedEndpoint.length === 1) {
        connectedContact.addConnection(matchedEndpoint[0], {
          success: function (data) {
            console.log("transfer success");
            holdIncomingCall();
          },
          failure: function (err) {
            console.log("transfer failed");
            console.log(err);
          }
        });
      } else {
        console.log("fail to find out matched agent, matched agent:");
        console.log(matchedEndpoint);
      }
    },
    failure: function (err) {
      console.log("Cannot get Queue ARNs");
      console.log(err);
    }
  });
};
CallAgentDirect = function (agentQuickConnectName) {
  updatedAgent.getEndpoints(updatedAgent.getAllQueueARNs(), {
    success: function (data) {
      console.log("Get Queue ARNs");
      let { endpoints } = data;
      let matchedEndpoints = endpoints.filter(ep => String(ep.name).toLowerCase() === agentQuickConnectName);
      if (matchedEndpoints.length === 1) {
        var endpoint = connect.Endpoint.byPhoneNumber(agentToAgentCallNo);
        console.log(endpoint);
        updatedAgent.connect(endpoint, {
          success: function () {
            agentDirectEndPoint = matchedEndpoints[0];
          },
          failure: function () {}
        });
      } else {
        console.log("fail to find out matched agent, matched agent:");
        console.log(matchedEndpoints);
      }
    },
    failure: function (err) {
      console.log("Cannot get Queue ARNs");
      console.log(err);
    }
  });
  placeCall();
};
transferManual = function (phoneNumber) {
  let endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
  connectedContact.addConnection(endpoint, {
    success: function (data) {
      console.log("transfer success");
      $("#transfer-call-dial-form").css("display", "none");
      holdIncomingCall();

      /* connectedContact.getAgentConnection().destroy({
        success: function() {
          console.log(`destroying ${connectedContact}`);
        },
        failure: function(error) {
          console.log("failed to destroy current contact");
          console.log(error);
        }
      }); */
    },
    failure: function (err) {
      console.log("transfer failed");
      console.log(err);
    }
  });
};

accountPlaceCall = function (number) {
  console.log({ x: "accountPlaceCall", number });
  let prefix = number.slice(0, 3);
  if (prefix !== "+61") number = "+61" + number.slice(1);
  console.log(number);
  placeCall(number);
};

getAgentInfo = function () {
  return updatedAgent;
};
getIsContactInfo = function () {
  return isOnCall;
};

getNumberOfConnectedCall = function () {
  return numberOfConnectedConnections;
};

setState = function (state, name) {
  if (updatedAgent && typeof updatedAgent !== undefined) {
    var routableStates = updatedAgent.getAgentStates();
    console.table([...routableStates]);
    var routableState = undefined;

    if (state) routableState = routableStates.filter(x => x.type == state);
    else if (name) routableState = routableStates.filter(x => x.name === name);
    updatedAgent.setState(routableState[0]);
  }
};

printConnections = function () {
  let conns = connectedContact.getConnections();
  let aliveFirstConn = connectedContact.getActiveInitialConnection();
  let aliveSecondConn = connectedContact.getSingleActiveThirdPartyConnection();
  console.table(conns.map(c => ({ id: c.getConnectionId(), status: c.getStatus() })));
  console.log({ id: aliveFirstConn.getConnectionId(), status: aliveFirstConn.getStatus() });
  console.log({ id: aliveSecondConn.getConnectionId(), status: aliveSecondConn.getStatus() });
};

//Feedback pop up control
reportFeedback = function () {
  $("#feedbackBtn").addClass("feedbackBtnClicked");
  //$("#feedbackBtnIcon").css("display", "none");
  //$("#feedbackBtnIconClicked").css("display", "inline");
  $("#feedbackBtn").popover("show");
  $(".option").keydown(function (e) {
    if (e.which === 13) {
      $(this).click();
    }
  });
  $(".feedbackBtnClicked").hover(
    function () {
      $("#feedbackBtnIconClicked").css("display", "none");
      $("#feedbackBtnIcon").css("display", "inline");
    },
    function () {
      $("#feedbackBtnIconClicked").css("display", "inline");
      $("#feedbackBtnIcon").css("display", "none");
    }
  );
};

closePopover = function () {
  $(".feedbackBtnClicked").off("mouseenter mouseleave");
  $("#feedbackBtn").removeClass("feedbackBtnClicked");
  $("#feedbackBtnIconClicked").css("display", "none");
  $("#feedbackBtnIcon").css("display", "inline");
  $("#feedbackBtn").popover("hide");
  $(".popover-body").html($("#feedbackOptionWrapper"));
};

$(document).ready(function () {
  $("#feedbackBtn").keydown(function (e) {
    if (e.which === 13) {
      $("#feedbackBtn").click();
    }
  });
  $("#feedbackBtn").popover({
    animation: false,
    html: true,
    content: function () {
      var content = $(this).attr("data-popover-content");
      return $(content).children("#feedbackOptionWrapper").html();
    }
  });
});

$(document).on("click", "#closeIcon", closePopover);

$(document).on("click", ".option", function () {
  const experienceHtml = $("#feedbackExperienceWrapper").html();
  $(".popover-body").html(experienceHtml);
  //$(".experienceHeader").attr("tabindex", 12);
  $(".expFirstOption").attr("tabindex", 18);
  $(".expSecondOption").attr("tabindex", 19);
  $(".expthirdOption").attr("tabindex", 20);
  $(".closeIcon").attr("tabindex", 21);
  $(".expOption").keydown(function (e) {
    if (e.which === 13) {
      $(this).click();
    }
  });
  $(".closeIcon").keydown(function (e) {
    if (e.which === 13) {
      $(this).click();
      $("#feedbackBtn").focus();
    } else if (!e.shiftKey && e.which === 9 && $("this:focus")) {
      e.preventDefault();
      $(".expFirstOption").focus();
    }
  });
  $(".closeIcon").click(function () {
    $("#feedbackBtn").focus();
  });
  $(".expFirstOption").keydown(function (e) {
    if (e.shiftKey && e.which === 9 && $("this:focus")) {
      e.preventDefault();
      $(".closeIcon").focus();
    }
  });
  $(".expFirstOption").focus();
});

$(document).on("click", ".expOption", function () {
  const successfulHtml = $("#successfulWrapper").html();
  $(".popover-body").html(successfulHtml);
  //$(".successfulMark").attr("tabindex", 15);
  $(".successfulSection").attr("tabindex", 22);
  $(".closeIcon").attr("tabindex", 23);
  $(".closeIcon").keydown(function (e) {
    if (e.which === 13) {
      $(this).click();
      $("#feedbackBtn").focus();
    } else if (e.which === 9 && $("this:focus")) {
      e.preventDefault();
      $(".successfulSection").focus();
    }
  });
  $(".closeIcon").click(function () {
    $("#feedbackBtn").focus();
  });
  $(".successfulSection").keydown(function (e) {
    if (e.shiftKey && e.which === 9 && $("this:focus")) {
      e.preventDefault();
      $(".closeIcon").focus();
    }
  });
  $(".closeIcon").focus();
});

$("#feedbackBtn").on("shown.bs.popover", function () {
  popoverOpened = true;
  //$(".optionHeader").attr("tabindex", 8);
  //$(".optionSubHeader").attr("tabindex", 9);
  $(".firstOption").attr("tabindex", 8);
  $(".secondOption").attr("tabindex", 9);
  $(".thirdOption").attr("tabindex", 10);
  $(".fourthOption").attr("tabindex", 11);
  $(".fifthOption").attr("tabindex", 12);
  $(".sixthOption").attr("tabindex", 13);
  $(".seventhOption").attr("tabindex", 14);
  $(".eighthOption").attr("tabindex", 15);
  $(".ninethOption").attr("tabindex", 16);
  $(".closeIcon").attr("tabindex", 17);
  $(".closeIcon").keydown(function (e) {
    if (e.which === 13) {
      $(this).click();
      $("#feedbackBtn").focus();
    } else if (!e.shiftKey && e.which === 9 && $("this:focus")) {
      e.preventDefault();
      $(".firstOption").focus();
    }
  });
  $(".closeIcon").click(function () {
    $("#feedbackBtn").focus();
  });
  $(".firstOption").keydown(function (e) {
    if (e.shiftKey && e.which === 9 && $("this:focus")) {
      e.preventDefault();
      $(".closeIcon").focus();
    }
  });
  $(".firstOption").focus();
});

$("#feedbackBtn").on("hidden.bs.popover", function () {
  popoverOpened = false;
});

$(document).keyup(function (e) {
  if (e.which === 27 && popoverOpened) {
    closePopover();
    $("#feedbackBtn").focus();
  }
});
// end of feedback control
